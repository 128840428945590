<template>
  <div class="SignupStep2">
    <div class="title has-text-centered p-v-l is-4">
      {{ $t(`pages.onboarding.step_2.cover_text`) }}
    </div>

    <form @submit.prevent="goNext">
      sdadas
    </form>
    <div class="buttons no-wrap is-aligned-end OnboardingStep__actions">
      <v-button
        class="is-dark is-width-full-mobile"
        data-testid="OnboardingStep2_BackButton"
        @click="goPrev"
      >
        {{ $t('general.prev') }}
      </v-button>

      <v-button
        class="is-primary is-width-full-mobile"
        data-testid="OnboardingStep2_NextButton"
        @click="proceed"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import OnboardingStepPrototype, { createComputedFormField } from '@/components/onboarding/OnboardingStepPrototype'

/**
 * @module SignupStep2
 */
export default {
  name: 'SignupStep2',
  extends: OnboardingStepPrototype,
  computed: {
    plan: createComputedFormField('plan'),
    payment_method_id: createComputedFormField('payment_method_id'),
    plan_duration: createComputedFormField('plan_duration')
  }
}
</script>
